import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { accountant } from "./constant";
import {
  assignAccountantApi,
  getAllAccountants,
  getAllAccountantsApi,
  getClientAssigned,
  importAccountantApi,
  searchAccountantApi,
  uploadProfileApiAcc,
  exportAccountantApi,
  getImportedAccountantsApi
} from "./api";

function* searchAccountantFunction({ payload }) {
  try {
    yield put({
      type: accountant.SEARCH_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(searchAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.SEARCH_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: accountant.SEARCH_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.SEARCH_ACCOUNTANT_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* assignAccountantFunction({ payload }) {
  try {
    yield put({
      type: accountant.ASSIGN_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(assignAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.ASSIGN_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: accountant.ASSIGN_ACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: accountant.ASSIGN_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.ASSIGN_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.ASSIGN_ACCOUNTANT_RESET,
      payload: {},
    });
  }
}

function* getAssignedAccountantFunction({ payload }) {
  try {
    yield put({
      type: accountant.GET_ASSIGNED_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(getClientAssigned, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.GET_ASSIGNED_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      // yield put({
      //   type: accountant.GET_ASSIGNED_ACCOUNTANT_RESET,
      //   payload: {},
      // });
    } else {
      yield put({
        type: accountant.GET_ASSIGNED_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.GET_ASSIGNED_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.GET_ASSIGNED_ACCOUNTANT_RESET,
      payload: {},
    });
  }
}

function* getAllAccountantsFunction({ payload }) {
  try {
    yield put({
      type: accountant.GET_ALL_ACCOUNTANTS_LOADING,
      payload: {},
    });
    const response = yield call(getAllAccountantsApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.GET_ALL_ACCOUNTANTS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: accountant.GET_ALL_ACCOUNTANTS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: accountant.GET_ALL_ACCOUNTANTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.GET_ALL_ACCOUNTANTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.GET_ALL_ACCOUNTANTS_RESET,
      payload: {},
    });
  }
}

function* updateProfileFunction(data) {
  try {
    yield put({
      type: accountant.UPDATE_PROFILE_LOADING,
      payload: {},
    });
    const response = yield call(uploadProfileApiAcc, data);
    if (response.data.status) {
      yield put({
        type: accountant.UPDATE_PROFILE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: accountant.UPDATE_PROFILE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: accountant.UPDATE_PROFILE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.UPDATE_PROFILE_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.UPDATE_PROFILE_RESET,
      payload: {},
    });
  }
}

function* importAccountentSaga({ payload }) {
  try {
    yield put({
      type: accountant.IMPORT_ACCOUNTANT_LOADING_POST,
      payload: {},
    });
    const response = yield call(importAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.IMPORT_ACCOUNTANT_SUCCESS_POST,
        payload: { ...response.data },
      });
      yield put({
        type: accountant.IMPORT_ACCOUNTANT_RESET_POST,
        payload: {},
      });
    } else {
      yield put({
        type: accountant.IMPORT_ACCOUNTANT_ERROR_POST,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.IMPORT_ACCOUNTANT_ERROR_POST,
      payload: error?.data,
    });
    yield put({
      type: accountant.IMPORT_ACCOUNTANT_RESET_POST,
      payload: {},
    });
  }
}

function* exportAccountantFunction({ payload }) {
  try {
    yield put({
      type: accountant.EXPORT_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(exportAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.EXPORT_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      // yield put({
      //   type: accountant.EXPORT_ACCOUNTANT_RESET,
      //   payload: {},
      // });
    } else {
      yield put({
        type: accountant.EXPORT_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.EXPORT_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.EXPORT_ACCOUNTANT_RESET,
      payload: {},
    });
  }
}
function* importedAccountantFunction({ payload }) {
  try {
    yield put({
      type: accountant.GET_IMPORTED_ACCOUNTANTS_LOADING,
      payload: {},
    });
    const response = yield call(getImportedAccountantsApi, { payload });
    if (response.data.status) {
      yield put({
        type: accountant.GET_IMPORTED_ACCOUNTANTS_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: accountant.GET_IMPORTED_ACCOUNTANTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: accountant.GET_IMPORTED_ACCOUNTANTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: accountant.GET_IMPORTED_ACCOUNTANTS_RESET,
      payload: {},
    });
  }
}

export function* searchAccountantSaga(): any {
  yield takeEvery(accountant.SEARCH_ACCOUNTANT, searchAccountantFunction);
}

export function* assignAccountantSaga(): any {
  yield takeEvery(accountant.ASSIGN_ACCOUNTANT, assignAccountantFunction);
}

export function* getAsssignedAccountantSaga(): any {
  yield takeEvery(
    accountant.GET_ASSIGNED_ACCOUNTANT,
    getAssignedAccountantFunction
  );
}

export function* getAllAccountantsSaga(): any {
  yield takeEvery(accountant.GET_ALL_ACCOUNTANTS, getAllAccountantsFunction);
}

export function* updateProfileSaga(): any {
  yield takeEvery(accountant.UPDATE_PROFILE_ACC, updateProfileFunction);
}

export function* postImportAccountent(): any {
  yield takeEvery(accountant.IMPORT_ACCOUNTANT_POST, importAccountentSaga);
}

export function* exportAccountantSaga(): any {
  yield takeEvery(accountant.EXPORT_ACCOUNTANT, exportAccountantFunction);
}
export function* importedAccountantSaga(): any {
  yield takeEvery(accountant.GET_IMPORTED_ACCOUNTANTS, importedAccountantFunction);
}

function* AccountantSaga(): any {
  yield all([
    fork(searchAccountantSaga),
    fork(assignAccountantSaga),
    fork(getAsssignedAccountantSaga),
    fork(getAllAccountantsSaga),
    fork(updateProfileSaga),
    fork(postImportAccountent), fork(exportAccountantSaga),
    fork(importedAccountantSaga)
  ]);
}

export default AccountantSaga;
