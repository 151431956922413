import { languages } from "./constant";
const initial_state = {
    data: [],
    message: "",
    loading: false,
};

export const getLanguagesReducer = (state = initial_state, action) => {
    switch (action.type) {
        case languages.GET_LANGUAGES_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case languages.GET_LANGUAGES_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case languages.GET_LANGUAGES_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export const addLanguageReducer = (state = initial_state, action) => {
    switch (action.type) {
        case languages.POST_LANGUAGES_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case languages.POST_LANGUAGES_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case languages.POST_LANGUAGES_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case languages.POST_LANGUAGES_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const updateLanguagesReducer = (state = initial_state, action) => {
    switch (action.type) {
        case languages.UPDATE_LANGUAGES_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case languages.UPDATE_LANGUAGES_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case languages.UPDATE_LANGUAGES_RESET:
            return {
                data: state,
                loading: false,
            };
        case languages.UPDATE_LANGUAGES_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const deleteLanguagesReducer = (state = initial_state, action) => {
    // console.log(state, "here is stateeee");
    switch (action.type) {
        case languages.DELETE_LANGUAGES_LOADING:
            return {
                ...state,
                loading: true,
            };
        case languages.DELETE_LANGUAGES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case languages.DELETE_LANGUAGES_ERROR:
            return {
                ...state,
                loading: false,
            };
        case languages.DELETE_LANGUAGES_RESET:
            return initial_state;
        default:
            return state;
    }
};
