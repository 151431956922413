import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function getLanguagesApiEndPoint(data): any {
    return api.get(URL.GET_LANGUAGES, data);
}

export function postLangaugesApiEndPoint(data): any {
    return api.create(URL.ADD_LANGUAGES, data?.payload);
}

export function updateLanguagesApiEndPoint(data): any {
    return api.update(`${URL.UPDATE_LANGUAGES}`, data?.payload);
}

export function deleteLanguagesApiEndPoint(data): any {
    const id = data?.payload;
    // console.log(data, "here is the dataaaa");
    return api.delete(URL.DELETE_LANGUAGES, data?.id);
}
