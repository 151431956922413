import { charge } from "./constants";

const add_charge_initial_state = {
    data: [],
    message: '',
    loading: false
}

const get_charge_initial_state = {
    data: [],
    message: '',
    loading: false
}

const delete_charge_initial_state = {
    data: [],
    message: '',
    loading: false
}

const update_charge_initial_state = {
    data: [],
    message: '',
    loading: false
}
export const addChargeReducer = (state = add_charge_initial_state, action) => {
    switch (action.type) {
        case charge.ADD_CHARGE_LOADING:
            return {
                data: add_charge_initial_state.data,
                loading: true,
            };
        case charge.ADD_CHARGE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case charge.ADD_CHARGE_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};


export const getChargeReducer = (state = get_charge_initial_state, action) => {
    switch (action.type) {
        case charge.GET_CHARGE_LOADING:
            return {
                data: get_charge_initial_state.data,
                loading: true,
            };
        case charge.GET_CHARGE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case charge.GET_CHARGE_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export const deleteChargeReducer = (state = delete_charge_initial_state, action) => {
    switch (action.type) {
        case charge.DELETE_CHARGE_LOADING:
            return {
                data: delete_charge_initial_state.data,
                loading: true,
            };
        case charge.DELETE_CHARGE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case charge.DELETE_CHARGE_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export const updateChargeReducer = (state = update_charge_initial_state, action) => {
    switch (action.type) {
        case charge.UPDATE_CHARGE_LOADING:
            return {
                data: update_charge_initial_state.data,
                loading: true,
            };
        case charge.UPDATE_CHARGE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case charge.UPDATE_CHARGE_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};