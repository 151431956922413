import { getPaymentsApi } from './api';
import { payments } from './constants';
import { all, fork, takeEvery, call, put } from 'redux-saga/effects';

function* getPaymentsFunction({ payload }) {
    try {
        yield put({
            type: payments.GET_PAYMENTS_LOADING,
            payload: {}
        });
        const response = yield call(getPaymentsApi, { payload });
        if (response.data.status) {
            yield put({
                type: payments.GET_PAYMENTS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: payments.GET_PAYMENTS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: payments.GET_PAYMENTS_ERROR,
            payload: { message: error?.message },
        });
    }
}

export function* getPaymentsSaga(): any {
    yield takeEvery(payments.GET_PAYMENTS, getPaymentsFunction)
}

function* paymentsSaga(): any {
    yield all([
        fork(getPaymentsSaga),
    ])
}

export default paymentsSaga;