import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { charge } from "./constants";
import {
    addChargeApi, getChargesApi, deleteChargeApi,
    updateChargeApi
} from "./api";

function* addChargeFunction(data) {
    try {
        yield put({
            type: charge.ADD_CHARGE_LOADING,
            payload: {},
        });
        const response = yield call(addChargeApi, data);
        if (response.data.status) {
            yield put({
                type: charge.ADD_CHARGE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: charge.ADD_CHARGE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: charge.ADD_CHARGE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: charge.ADD_CHARGE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: charge.ADD_CHARGE_RESET,
            payload: {},
        });
    }
}

function* getChargeFunction(data) {
    try {
        yield put({
            type: charge.GET_CHARGE_LOADING,
            payload: {},
        });
        const response = yield call(getChargesApi, data);
        if (response.data.status) {
            yield put({
                type: charge.GET_CHARGE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: charge.GET_CHARGE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: charge.GET_CHARGE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: charge.GET_CHARGE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: charge.GET_CHARGE_RESET,
            payload: {},
        });
    }
}

function* deleteChargeFunction(data) {
    try {
        yield put({
            type: charge.DELETE_CHARGE_LOADING,
            payload: {},
        });
        const response = yield call(deleteChargeApi, data);
        if (response.data.status) {
            yield put({
                type: charge.DELETE_CHARGE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: charge.DELETE_CHARGE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: charge.DELETE_CHARGE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: charge.DELETE_CHARGE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: charge.DELETE_CHARGE_RESET,
            payload: {},
        });
    }
}

function* updateChargeFunction(data) {
    try {
        yield put({
            type: charge.UPDATE_CHARGE_LOADING,
            payload: {},
        });
        const response = yield call(updateChargeApi, data);
        if (response.data.status) {
            yield put({
                type: charge.UPDATE_CHARGE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: charge.UPDATE_CHARGE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: charge.UPDATE_CHARGE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: charge.UPDATE_CHARGE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: charge.UPDATE_CHARGE_RESET,
            payload: {},
        });
    }
}
export function* addChargeSaga(): any {
    yield takeEvery(charge.ADD_CHARGE, addChargeFunction);
}

export function* getChargeSaga(): any {
    yield takeEvery(charge.GET_CHARGE, getChargeFunction);
}
export function* deleteChargeSaga(): any {
    yield takeEvery(charge.DELETE_CHARGE, deleteChargeFunction);
}
export function* updateChargeSaga(): any {
    yield takeEvery(charge.UPDATE_CHARGE, updateChargeFunction);
}
function* ChargeSaga(): any {
    yield all([
        fork(addChargeSaga),
        fork(getChargeSaga),
        fork(deleteChargeSaga),
        fork(updateChargeSaga)
    ]);
}
export default ChargeSaga;