import { accountant } from "./constant";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};
const import_accountant_initial_state = {
  data: [],
  message: "",
  loading: false,
};
const export_accountant_initial_state = {
  data: [],
  message: "",
  loading: false,
};
const get_imported_accountant_initial_state = {
  data: [],
  message: "",
  loading: false,
};
const UPDATE_PROFILE_INITIAL_STATE = {
  updateAccountantProfile: [],
  loading: false,
  message: "",
}

export const searchAccountantReducer = (state = initial_state, action) => {
  switch (action.type) {
    case accountant.SEARCH_ACCOUNTANT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case accountant.SEARCH_ACCOUNTANT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.SEARCH_ACCOUNTANT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const assignAccountantReducer = (state = initial_state, action) => {
  switch (action.type) {
    case accountant.ASSIGN_ACCOUNTANT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case accountant.ASSIGN_ACCOUNTANT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.ASSIGN_ACCOUNTANT_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case accountant.ASSIGN_ACCOUNTANT_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const getAssignedAccountantReducer = (state = initial_state, action) => {
  switch (action.type) {
    case accountant.EXPORT_ACCOUNTANT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case accountant.EXPORT_ACCOUNTANT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.EXPORT_ACCOUNTANT_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case accountant.EXPORT_ACCOUNTANT_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const getAllAccountantsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case accountant.GET_ALL_ACCOUNTANTS_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case accountant.GET_ALL_ACCOUNTANTS_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    // case accountant.GET_ALL_ACCOUNTANTS_RESET:
    //   return {
    //     data: initial_state.data,
    //     loading: false,
    //   };
    case accountant.GET_ALL_ACCOUNTANTS_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};
export const updateProfileReducerAcc = (
  state = UPDATE_PROFILE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case accountant.UPDATE_PROFILE_LOADING:
      return {
        updateAccountantProfile: state.updateAccountantProfile,
        loading: true,
      };
    case accountant.UPDATE_PROFILE_SUCCESS:
      return {
        updateAccountantProfile: action?.payload,
        loading: false,
      };
    case accountant.UPDATE_PROFILE_ERROR:
      return {
        updateAccountantProfile: action?.payload,
        loading: false,
      }
    case accountant.UPDATE_PROFILE_RESET:
      return UPDATE_PROFILE_INITIAL_STATE
    default:
      return { ...state };

  }
};


export const importAnnouncementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case accountant.IMPORT_ACCOUNTANT_LOADING_POST:
      return {
        data: initial_state.data,
        loading: true,
      };
    case accountant.IMPORT_ACCOUNTANT_SUCCESS_POST:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.IMPORT_ACCOUNTANT_RESET_POST:
      return {
        data: initial_state.data,
        loading: false,
      };
    case accountant.IMPORT_ACCOUNTANT_ERROR_POST:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const exportAccountantReducer = (state = export_accountant_initial_state, action) => {
  switch (action.type) {
    case accountant.EXPORT_ACCOUNTANT_LOADING:
      return {
        data: export_accountant_initial_state.data,
        loading: true,
      };
    case accountant.EXPORT_ACCOUNTANT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.EXPORT_ACCOUNTANT_RESET:
      return {
        data: export_accountant_initial_state.data,
        loading: false,
      };
    case accountant.EXPORT_ACCOUNTANT_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};


export const getImportedAccountantReducer = (state = get_imported_accountant_initial_state, action) => {
  switch (action.type) {
    case accountant.GET_IMPORTED_ACCOUNTANTS_LOADING:
      return {
        data: get_imported_accountant_initial_state.data,
        loading: true,
      };
    case accountant.GET_IMPORTED_ACCOUNTANTS_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case accountant.GET_IMPORTED_ACCOUNTANTS_RESET:
      return {
        data: get_imported_accountant_initial_state.data,
        loading: false,
      };
    case accountant.GET_IMPORTED_ACCOUNTANTS_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};