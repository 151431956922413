export const languages = {
    //   GET
    GET_LANGUAGES: "GET_LANGUAGES",
    GET_LANGUAGES_LOADING: "GET_LANGUAGES_LOADING",
    GET_LANGUAGES_SUCCESS: "GET_LANGUAGES_SUCCESS",
    GET_LANGUAGES_ERROR: "GET_LANGUAGES_ERROR",
    GET_LANGUAGES_RESET: "GET_LANGUAGES_RESET",
    //   POST
    POST_LANGUAGES: "POST_LANGUAGES",
    POST_LANGUAGES_LOADING: "POST_LANGUAGES_LOADING",
    POST_LANGUAGES_SUCCESS: "POST_LANGUAGES_SUCCESS",
    POST_LANGUAGES_ERROR: "POST_LANGUAGES_ERROR",
    POST_LANGUAGES_RESET: "POST_LANGUAGES_RESET",
    //   UPDATE
    UPDATE_LANGUAGES: "UPDATE_LANGUAGES",
    UPDATE_LANGUAGES_LOADING: "UPDATE_LANGUAGES_LOADING",
    UPDATE_LANGUAGES_SUCCESS: "UPDATE_LANGUAGES_SUCCESS",
    UPDATE_LANGUAGES_ERROR: "UPDATE_LANGUAGES_ERROR",
    UPDATE_LANGUAGES_RESET: "UPDATE_LANGUAGES_RESET",
    //   DELETE
    DELETE_LANGUAGES: "DELETE_LANGUAGES",
    DELETE_LANGUAGES_LOADING: "DELETE_LANGUAGES_LOADING",
    DELETE_LANGUAGES_SUCCESS: "DELETE_LANGUAGES_SUCCESS",
    DELETE_LANGUAGES_ERROR: "DELETE_LANGUAGES_ERROR",
    DELETE_LANGUAGES_RESET: "DELETE_LANGUAGES_RESET",
};
