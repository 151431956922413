export const USER_LOGIN = "/api/user/userLogin";
export const BUSINESS_EMAIL_OTP = "/api/user/emailVerify";
export const VERIFY_EMAIL_OTP = "/api/user/verifyOtp";
export const SUB_ACCOUNTANT_EMAIL_SEND_OTP = "/api/user/sendOtp?";
export const GET_COMPANIES_BY_SEARCH = "/api/user/getCompaniesBySearch?";
export const REGISTER_USER = "/api/user/registerUser";
export const COMPANY_CHECK_VERIFY = "/api/user/organizationCheck";
export const COMPANY_CREATE = "/api/onboard/companyRequest";
export const RESET_PASSWORD_EMAIL = "/api/user/forgotPassword";
export const RESET_PASSWORD_VERITY = "/api/user/changePassword";
export const UPDATE_PROFILE = "/api/user/clientUpdate";
// export const Get_PROFILE = "/api/user/getClientProfilelll";
export const GET_ORGNISATION = "/api/organization/getUserOrganization?";
export const UPDATE_ORGNISATION = "/api/organization/updateUserOrganization";
export const UPDATE_PASSWORD = "/api/user/changePassword";
export const GET_SUBUSER = "/api/user/getClientsubUser";
export const ADD_SUBUSER = "/api/user/registerUser";
export const UPDATE_SUBUSER = "/api/user/clientUpdate";

// NEW CODE
export const GET_SKILL = "/api/user/getSkills?";
export const SIGNUP_ACCOUNT = "/api/user/registerUser";
export const ACCOUNT_DETAIL_ACCOUNT = "/api/user/accountDetails";
export const ADD_SUBACCOUNTANT = "/api/user/registerUser";
export const GET_SUBACCOUNTANT = "/api/user/getAccountantSubUsers";
export const UPDATE_SUBACCOUNTANT = "/api/user/accountantUpdate";
export const DELETE_SUBACCOUNTANT = "/api/user/subUserDelete?";
export const GET_ACCOUNTANT_PROFILE = "/api/user/getAccountantProfile";
export const ACCOUNT_SETTING_ACCOUNT = "/api/user/accountantUpdate";
export const EMAIL_VERIFY_SEND = "/api/organization/organizationEmailVerify";
export const EMAIL_VERIFY_DELETE = "/api/organization/deleteMail?";
export const OTP_VERIFY_SEND = "/api/organization/otpVerify";
export const GET_SEARCH_ACCOUNTANT_LIST = "/api/onboard/accountantSearch?";

// Super Admin Routes
export const GET_ANNOUNCEMENT = "/api/announcement/getAnnouncement";
export const POST_ANNOUNCEMENT = "/api/announcement/addAnnouncement";
export const UPDATE_ANNOUNCEMENT = "/api/announcement/updateAnnouncement";
export const DELETE_ANNOUNCEMENT = "/api/announcement/deleteAnnouncement";
export const GET_CLIENT = "/api/onboard/getAllClients";
export const SEARCH_ACCOUNTANT = "/api/onboard/accountantSearch";
export const ASSIGN_ACCOUNTANT = "/api/onboard/accountantAssign";
export const SEARCH_CLIENT = "/api/onboard/clientSearch";
export const GET_ASSIGNED_ACCOUNTANT = "/api/onboard/clientSearch";
export const GET_ALL_ACCOUNTANTS = "/api/onboard/getAllAccountants";
export const ACCOUNTANT_IMPORT = "api/accountant/accountantImport";
export const ACCOUNTANT_EXPORT = "/api/onboard/getExportAccountants";
export const IMPORTED_ACCOUNTANTS = '/api/accountant/getImportedAccountant';
export const GET_LANGUAGES = "api/master/getLanguage";
export const ADD_LANGUAGES = "api/master/addLanguage";
export const DELETE_LANGUAGES = "api/master/deleteLanguage";
export const UPDATE_LANGUAGES = "api/master/updateLanguage";
// add charges
export const ADD_CHARGE = '/api/master/addCharges';
export const GET_CHARGES = '/api/master/getCharges';
export const DELETE_CHARGE = '/api/master/deleteCharges';
export const UPDATE_CHARGE = '/api/master/updateCharges';

// Payments
export const GET_PAYMENTS = '/api/onboard/getAllPayments'