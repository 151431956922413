import { getClientsApi, uploadProfileApi, uploadProfileApiCli } from "./api";
import { client } from "./constant";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

function* getClientFunction({ payload }) {
  try {
    yield put({
      type: client.GET_CLIENT_LOADING,
      payload: {},
    });
    const response = yield call(getClientsApi, { payload });
    if (response.data.status) {
      yield put({
        type: client.GET_CLIENT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: client.GET_CLIENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: client.GET_CLIENT_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* updateProfileFunction(data) {
  try {
    yield put({
      type: client.UPDATE_PROFILE_LOADING,
      payload: {},
    });
    const response = yield call(uploadProfileApiCli, data);
    if (response.data.status) {
      yield put({
        type: client.UPDATE_PROFILE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: client.UPDATE_PROFILE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: client.UPDATE_PROFILE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: client.UPDATE_PROFILE_ERROR,
      payload: error?.data,
    });
    yield put({
      type: client.UPDATE_PROFILE_RESET,
      payload: {},
    });
  }
}

export function* getClientsSaga(): any {
  yield takeEvery(client.GET_CLIENT, getClientFunction);
}
export function* updateProfileSaga(): any {
  yield takeEvery(client.UPDATE_PROFILE, updateProfileFunction);
}

function* clientSaga(): any {
  yield all([
    fork(getClientsSaga),
    fork(updateProfileSaga)
  ]);

}

export default clientSaga;
