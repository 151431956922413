export const client = {
  GET_CLIENT: "GET_CLIENT",
  GET_CLIENT_LOADING: "GET_CLIENT_LOADING",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_ERROR: "GET_CLIENT_ERROR",
  GET_CLIENT_RESET: "GET_CLIENT_RESET",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  UPDATE_PROFILE_RESET: "UPDATE_PROFILE_RESET",
};
