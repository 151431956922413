export const accountant = {
  //   Search
  SEARCH_ACCOUNTANT: "SEARCH_ACCOUNTANT",
  SEARCH_ACCOUNTANT_LOADING: "SEARCH_ACCOUNTANT_LOADING",
  SEARCH_ACCOUNTANT_SUCCESS: "SEARCH_ACCOUNTANT_SUCCESS",
  SEARCH_ACCOUNTANT_ERROR: "SEARCH_ACCOUNTANT_ERROR",
  SEARCH_ACCOUNTANT_RESET: "SEARCH_ACCOUNTANT_RESET",
  // Assign
  ASSIGN_ACCOUNTANT: "ASSIGN_ACCOUNTANT",
  ASSIGN_ACCOUNTANT_LOADING: "ASSIGN_ACCOUNTANT_LOADING",
  ASSIGN_ACCOUNTANT_SUCCESS: "ASSIGN_ACCOUNTANT_SUCCESS",
  ASSIGN_ACCOUNTANT_ERROR: "ASSIGN_ACCOUNTANT_ERROR",
  ASSIGN_ACCOUNTANT_RESET: "ASSIGN_ACCOUNTANT_RESET",
  // Get Assigned Accountant
  GET_ASSIGNED_ACCOUNTANT: "GET_ASSIGNED_ACCOUNTANT",
  GET_ASSIGNED_ACCOUNTANT_LOADING: "GET_ASSIGNED_ACCOUNTANT_LOADING",
  GET_ASSIGNED_ACCOUNTANT_SUCCESS: "GET_ASSIGNED_ACCOUNTANT_SUCCESS",
  GET_ASSIGNED_ACCOUNTANT_ERROR: "GET_ASSIGNED_ACCOUNTANT_ERROR",
  GET_ASSIGNED_ACCOUNTANT_RESET: "GET_ASSIGNED_ACCOUNTANT_RESET",
  // Get All Accountants
  GET_ALL_ACCOUNTANTS: "GET_ALL_ACCOUNTANTS",
  GET_ALL_ACCOUNTANTS_LOADING: "GET_ALL_ACCOUNTANTS_LOADING",
  GET_ALL_ACCOUNTANTS_SUCCESS: "GET_ALL_ACCOUNTANTS_SUCCESS",
  GET_ALL_ACCOUNTANTS_ERROR: "GET_ALL_ACCOUNTANTS_ERROR",
  GET_ALL_ACCOUNTANTS_RESET: "GET_ALL_ACCOUNTANTS_RESET",

  UPDATE_PROFILE_ACC: "UPDATE_PROFILE_ACC",
  UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  UPDATE_PROFILE_RESET: "UPDATE_PROFILE_RESET",

  IMPORT_ACCOUNTANT_POST: "IMPORT_ACCOUNTANT_POST",
  IMPORT_ACCOUNTANT_LOADING_POST: "IMPORT_ACCOUNTANT_LOADING_POST",
  IMPORT_ACCOUNTANT_SUCCESS_POST: "IMPORT_ACCOUNTANT_SUCCESS_POST",
  IMPORT_ACCOUNTANT_ERROR_POST: "IMPORT_ACCOUNTANT_ERROR_POST",
  IMPORT_ACCOUNTANT_RESET_POST: "IMPORT_ACCOUNTANT_RESET_POST",

  EXPORT_ACCOUNTANT: "EXPORT_ACCOUNTANT",
  EXPORT_ACCOUNTANT_LOADING: "EXPORT_ACCOUNTANT_LOADING",
  EXPORT_ACCOUNTANT_SUCCESS: "EXPORT_ACCOUNTANT_SUCCESS",
  EXPORT_ACCOUNTANT_ERROR: "EXPORT_ACCOUNTANT_ERROR",
  EXPORT_ACCOUNTANT_RESET: "EXPORT_ACCOUNTANT_RESET",

  GET_IMPORTED_ACCOUNTANTS: "GET_IMPORTED_ACCOUNTANTS",
  GET_IMPORTED_ACCOUNTANTS_LOADING: "GET_IMPORTED_ACCOUNTANTS_LOADING",
  GET_IMPORTED_ACCOUNTANTS_SUCCESS: "GET_IMPORTED_ACCOUNTANTS_SUCCESS",
  GET_IMPORTED_ACCOUNTANTS_ERROR: "GET_IMPORTED_ACCOUNTANTS_ERROR",
  GET_IMPORTED_ACCOUNTANTS_RESET: "GET_IMPORTED_ACCOUNTANTS_RESET",
};
