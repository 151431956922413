import { languages } from "./constant";

export const getLanguageList = (data): AuthAction => ({
    type: languages.GET_LANGUAGES,
    payload: data,
});

export const postLanguage = (data): AuthAction => ({
    type: languages.POST_LANGUAGES,
    payload: data,
});

export const updateLanguages = (data): AuthAction => ({
    type: languages?.UPDATE_LANGUAGES,
    payload: data,
});

export const deleteLanguages = (id): AuthAction => {
    return {
        type: languages?.DELETE_LANGUAGES,
        id,
    };
};
