import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function getAnnouncementsApi(data): any {
  const limit = data.payload?.limit ? data.payload.limit : 10;
  const page = data?.payload?.page ? data.payload.page : 1;
  return api.get(
    `${URL.GET_ANNOUNCEMENT}?role=SuperAdmin&page=${page}&limit=${limit}`
  );
}

export function searchAccountantAssignApi(data): any {
  return api.get(
    `${URL.SEARCH_ACCOUNTANT}?name=${data?.payload?.name}&clientId=${data?.payload?.clientId}`
  );
}

export function searchClientApi(data): any {
  return api.get(`${URL.SEARCH_CLIENT}?name=${data?.name}`);
}

export function postAnnouncementsApi(data): any {
  return api.create(`${URL.POST_ANNOUNCEMENT}`, data?.payload);
}

export function updateAnnouncementsApi(data): any {
  return api.update(`${URL.UPDATE_ANNOUNCEMENT}`, data?.payload);
}

export function deleteAnnouncementsApi(data): any {
  const id = data?.payload;
  // console.log(data, "here is the dataaaa");
  return api.delete(URL.DELETE_ANNOUNCEMENT, data?.id);
}
