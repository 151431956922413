import { charge } from './constants';

export const addChargeAction = (data): AuthAction => ({
    type: charge.ADD_CHARGE,
    payload: data,
})

export const getChargeAction = (data): AuthAction => ({
    type: charge.GET_CHARGE,
    payload: data,
})

export const deleteChargeAction = (data): AuthAction => ({
    type: charge.DELETE_CHARGE,
    payload: data,
})

export const updateChargeAction = (data): AuthAction => ({
    type: charge.UPDATE_CHARGE,
    payload: data,
})