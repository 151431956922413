import { payments } from "./constants";

const get_payments_initial_state = {
    data: [],
    message: '',
    loading: false,
}

export const getPaymentsReducer = (state = get_payments_initial_state, action) => {
    switch (action.type) {
        case payments.GET_PAYMENTS_LOADING:
            return {
                data: get_payments_initial_state.data,
                loading: true,
            };
        case payments.GET_PAYMENTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case payments.GET_PAYMENTS_ERROR:
            return {
                data: [],
                loading: false,
                message: action.payload,
            }
        default:
            return { ...state };
    }
}