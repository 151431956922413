import { accountant } from "./constant";

export const searchAccountant = (data): AuthAction => ({
  type: accountant.SEARCH_ACCOUNTANT,
  payload: data,
});

export const accountantAssign = (data): AuthAction => ({
  type: accountant.ASSIGN_ACCOUNTANT,
  payload: data,
});

export const getAccountantAssigned = (data): AuthAction => ({
  type: accountant.GET_ASSIGNED_ACCOUNTANT,
  payload: data,
});

export const getAllAccountants = (data): AuthAction => ({
  type: accountant.GET_ALL_ACCOUNTANTS,
  payload: data,
});
export const updateAccountant = (data): AuthAction => ({
  type: accountant.UPDATE_PROFILE_ACC,
  payload: data,
});

export const importAccountant = (data): AuthAction => ({
  type: accountant.IMPORT_ACCOUNTANT_POST,
  payload: data,
});

export const exportAccountantAction = (data): AuthAction => ({
  type: accountant.EXPORT_ACCOUNTANT,
  payload: data,
});

export const getImportedAccountantsAction = (data): AuthAction => ({
  type: accountant.GET_IMPORTED_ACCOUNTANTS,
  payload: data,
})