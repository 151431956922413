import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function searchAccountantApi(data): any {
  if (!data?.payload?.clientId) {
    return api.get(
      `${URL.SEARCH_ACCOUNTANT}?name=${data?.payload?.name ? data?.payload?.name : ''}`);
  }
  else {
    return api.get(
      `${URL.SEARCH_ACCOUNTANT}?name=${data?.payload?.name ? data?.payload?.name : ''}&clientId=${data?.payload?.clientId ? data?.payload?.clientId : ''}`);
  }
}

export function getClientAssigned(data): any {
  return api.get(
    `${URL.GET_ASSIGNED_ACCOUNTANT}?accountantId=${data?.payload?.accountantId}`
  );
}

export function getAllAccountantsApi(data): any {
  const skip = data.payload?.skip ? data.payload.skip : 1; // Destructure skip and search from data.payload
  const limit = data.payload?.limit ? data.payload.limit : 10; // Destructure skip and search from data.payload
  const searchQuery = data?.payload?.search || "";
  // Include the search query dynamically in the API call
  return api.get(
    `${URL.GET_ALL_ACCOUNTANTS}?skip=${skip}&limit=${10}&search=${searchQuery}`
  );
}

export function assignAccountantApi(data): any {
  const payload = {
    clientId: data?.payload?.clientId,
    accountantId: data?.payload?.accountantId,
    accountantAssignId: data?.payload?.accountantAssignId,
  };
  return api.create(`${URL.ASSIGN_ACCOUNTANT}`, payload);
}
export function uploadProfileApiAcc(payload): any {
  // console.log(payload, 'updateProfile')
  return api.update(URL.ACCOUNT_SETTING_ACCOUNT, payload?.payload);
}

export function importAccountantApi(data): any {
  return api.create(`${URL.ACCOUNTANT_IMPORT}`, data?.payload);
}

export function exportAccountantApi(data): any {
  return api.get(`${URL.ACCOUNTANT_EXPORT}`, data?.payload);
}

export function getImportedAccountantsApi(data): any {
  return api.get(`${URL.IMPORTED_ACCOUNTANTS}?limit=${data?.payload?.limit}&skip=${data?.payload?.skip}`);
}
